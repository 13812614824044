
































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { GlobalModule, ClientsModule, UserModule } from '@/store'
// import { clientsStatusesSelect, clientsStatusesArray, clientsTypes, clientsUnique, clientsSource } from '@/utilities'

@Component({
  metaInfo: {
    title: 'Воронка продаж'
  }
})
export default class Home extends Vue {
  public clients: Array<{[propName: string]: any}> | any
  public managers: Array<{[propName: string]: any}> | any
  public allClients: Array<{[propName: string]: any}> | any
  public statusesText: Array<string>
  public statusesColors: Array<string>
  public viewDataDialog: boolean
  public viewDataDialogData: any
  public search: string
  public filterManager: number
  public filterMonth: string | null
  public monthPickerMenu: boolean
  public tableRerender: boolean
  // public rerenderKeyUserSelect: boolean
  constructor() {
    super()
    this.viewDataDialog = false
    this.clients = []
    this.managers = []
    this.allClients = []
    this.filterMonth = null
    this.tableRerender = false
    this.viewDataDialogData = []
    this.search = ''
    this.filterManager = 0
    this.monthPickerMenu = false
    // this.rerenderKeyUserSelect = false
    this.statusesText = [
      'Новый клиент',
      'Обработанный',
      'Заявка на показ',
      'Показ совершён',
      'Одобрение ипотеки',
      'Аванс',
      'Выезд',
      'Провальные'
    ]

    this.statusesColors = [
      '#1565c0',
      '#00b6ff',
      '#29ff57',
      '#00d415',
      '#f7bd00',
      '#007a14',
      '#bd08ff',
      '#ff4a3d'
    ]
  }

  @GlobalModule.Getter('getLoading') loading!: boolean
  @ClientsModule.Action('requestMainPageClients') getPageData!: (filters?) => Promise<any>
  @UserModule.Getter('userInfo') userInfo!: { [propName: string]: any }

  @Watch('search') filterSearch() {
    if (this.search) {
      this.clients = this.allClients.filter((client) => {
        return (this.statusesText[client.status] + JSON.stringify(client)).toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })
    } else {
      this.clients = this.allClients
    }
  }

  // @Watch('userInfo') changeNoneField() {
  //   if (this.managers && this.managers.length > 0) {
  //     // eslint-disable-next-line
  //     this.managers[0] = { user_id: 0, title: this.userNoneField() }
  //     this.rerenderKeyUserSelect = !this.rerenderKeyUserSelect
  //   }
  // }

  getClientsByStatus(status = false) {
    return status !== false ? this.clients.filter(client => Number(client.status) === Number(status)) : this.clients
  }

  formatDate(date) {
    date = new Date(date)
    return date.getDate() + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
  }

  checkAdmin() {
    if (!this.userInfo || !this.userInfo.group) {
      return true
    }

    return this.userInfo.group.group_id !== 1 && this.userInfo.group.group_id !== 6
  }

  // userNoneField() {
  //   if (!this.userInfo || !this.userInfo.group) {
  //     return 'Не указано'
  //   }

  //   return this.userInfo.group.group_id !== 1 ? 'Не указано' : 'Все'
  // }

  getTimeByDate(date) {
    date = new Date(date)
    return ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2)
  }

  viewPhones(phones) {
    this.viewDataDialogData = phones ? phones.map(phone => { return { link: 'tel:' + phone, data: phone } }) : []
    this.viewDataDialog = true
  }

  viewMails(mails) {
    this.viewDataDialogData = mails ? mails.map(mail => { return { link: 'mailto:' + mail, data: mail } }) : []
    this.viewDataDialog = true
  }

  goToPrice(priceId) {
    this.$router.push('/prices/edit/' + priceId)
  }

  goToClient(clientId) {
    this.$router.push('/clients/' + clientId)
  }

  applyFilters() {
    this.clients = []
    this.getPageData({
      // eslint-disable-next-line
      user_id: this.filterManager,
      month: this.filterMonth
    })
      .then((res) => {
        // this.allClients = res.clients
        this.allClients = res.clients.map((client) => { return { ...client, showFull: false } }).sort((a, b) => b.client_id - a.client_id)
        this.clients = this.allClients
        // console.log(this.allClients.map(client => client.client_id))

        // eslint-disable-next-line
        this.managers = [{ user_id: 0, title: 'Все' }, ...res.managers]
        // this.clients = []
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  collapse(clientId) {
    const clientIndex = this.clients.findIndex((client) => client.client_id === clientId)
    this.clients[clientIndex].showFull = !this.clients[clientIndex].showFull
    this.tableRerender = !this.tableRerender
  }

  expandAll() {
    this.clients = this.clients.map((client) => {
      client.showFull = true
      return client
    })
    this.tableRerender = !this.tableRerender
  }

  collapseAll() {
    this.clients = this.clients.map((client) => {
      client.showFull = false
      return client
    })
    this.tableRerender = !this.tableRerender
  }

  created() {
    this.getPageData()
      .then((res) => {
        // this.allClients = res.clients
        this.allClients = res.clients.map((client) => { return { ...client, showFull: false } }).sort((a, b) => b.client_id - a.client_id)
        this.clients = this.allClients

        // eslint-disable-next-line
        this.managers = [{ user_id: 0, title: 'Все' }, ...res.managers]
        // this.clients = []
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }
}
